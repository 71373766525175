import React from 'react';
import ContactsCCom from '../componate/ContactsCCom';
import Contectus from '../componate/Contectus';

const Contact = () => {
    return (
        <>
            
        <Contectus/>
        <ContactsCCom/>
        </>
    );
}

export default Contact;
