import React, { useState } from 'react'
import { FaBars, FaWhatsapp } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { Carousel } from 'flowbite-react';
import { Link } from "react-router-dom"

export default function Header() {

    const [toggle, settoggle] = useState(false)
    function demo() {
        settoggle(!toggle)
    }
    const navbar = [
        {
            name: "HOME",
            link: '/'
        }
        ,
        {
            name: "Gallery",
            link: '/gallery'
        },

        {
            name: "ABOUT",
            link: '/about'
        },
        {
            name: "CONTACT",
            link: '/contact'
        }
    ]
    return (
        <div style={{ boxShadow: "0px 4px 0px rgb(0,0,0,0.05)" }} className='bg-white z-[5000] w-full  sticky top-0'>
            <header className='max-w-[1200px] mx-auto  z-50  flex items-center justify-between   py-4 box-border relative '>
                <img className='w-18  h-16 ml-7' src="images/logo.png" alt="" />
                <ul className='mx-10  justify-around w-full items-center text-[16px] font-bold md:flex  hidden'>
                    {
                        navbar.map(
                            (data, index) => {
                                return (
                                    <Link to={data.link} className='hover:text-[#407ab4] cursor-pointer'>
                                        {data.name}</Link>

                                )
                            }
                        )
                    }
                    <a target='_blank' href="https://api.whatsapp.com/send/?phone=9414305095&text=Hello%2C+I+am+interested+in your services">
                        <li className='flex justify-center items-center gap-2 border px-4 py-2 rounded-lg cursor-pointer hover:bg-black hover:text-[white]' ><FaWhatsapp className=' hover:text-[white]' /> ChatNow</li>
                    </a>


                </ul>
                {
                    toggle == false ?
                        <FaBars onClick={demo} className=' cursor-pointer md:hidden text-[25px] mr-[30px]' />
                        :
                        <ImCross onClick={demo} style={{ zIndex: "4545" }} className=' md:hidden cursor-pointer right-[30px] text-[25px] absolute' />
                }
                <ul style={{ zIndex: "4544" }} className={`flex flex-col bg-gray-300  h-screen top-[0px] absolute duration-700 w-full items-start  text-[16px] font-bold md:hidden justify-around  
    ${toggle == true ? 'left-0' : 'left-[-100%]'}

    `}>


                    {
                        navbar.map(
                            (data, index) => {
                                return (
                                    <Link onClick={demo} to={data.link} className='hover:text-blue-600 pl-12 cursor-pointer'>{data.name}</Link>
                                )
                            }
                        )
                    }

                </ul>

            </header>
        </div>



    )
}
