import React from 'react';
import MyGallery from '../componate/MyGallery'


const Gallery = () => {
    return (
        <> 
        <MyGallery/>
        </>
    );
}

export default Gallery;
