import React, { useEffect } from 'react'
import { Button, Checkbox, Label, TextInput } from 'flowbite-react';
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineMail } from "react-icons/md";
import { CiMobile4 } from "react-icons/ci";
import { IoIosArrowUp } from "react-icons/io";



import {  FaFacebook, FaInstagram } from "react-icons/fa";




export default function Footer() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Adds a smooth scrolling animation
        });
      }
      

 
    const contact = [
        {
            icon: <MdOutlineMail />,
            p: "hrprintsolution1@gmail.com",
            url: "mailto:hrprintsolution1@gmail.com"
        },
        {
            icon: <CiMobile4 />,
            p: "Call us: +91-9414305095",
            url: "tel:+919414305095"
        },
        {
            icon: <CiMobile4 />,
            p: "Call us: +91-9413626365",
            url: "tel:+919413626365"
        },
        {
            icon: <FaFacebook />,
            p: "hrprintsolution on Facebook",
            url: "https://www.facebook.com/hrprintsolution"
        },
        {
            icon: <FaInstagram />,
            p: "hrprintsolution on Instagram",
            url: "https://www.instagram.com/hrprintsolution"
        }
    ];


    return (
        <div style={{zIndex:-1}} className='w-full  mt-4 bg-[#205072]'>
            <div className='max-w-[1200px] py-4  mt-8 m-auto grid md:grid-cols-4 grid-cols-1 bg-[#205072]'>
                <div className='w-full flex justify-center flex-col items-center'>
                    <img src="images/logo.png" className='md:w-[80%] w-[50%]  py-4 ' alt="" />
                    <p className='text-white text-center'>© 2024 hrpracharwala.com</p>
                </div>
                <div className='md:p-4 sm:px-[100px] px-[50px]  py-4x'>
                    <div className=' px-2 py-4 text-[22px] text-white'>
                        Address
                    </div>

                    <div className='flex gap-2 pb-8'>
                        <div className='px-2 py-3'>
                            <FaLocationDot className='text-white block  hover:[#3fac9a]' />

                        </div>
                        <div className='text-white '>
                       
                            <h1 className='text-[22px] py-1'>Office</h1>
                            <p ><a target='_blank' className='text-white hover:[#3fac9a]' href="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3560.188915404387!2d75.834587!3d26.833943!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db738866c5715%3A0xad2e3dba27f5a8ed!2sHR%20Advertising!5e0!3m2!1sen!2sin!4v1721181077464!5m2!1sen!2sin">SA Shop no. 4, 5, Jagan Vihar, New Market, Jagatpura, Jaipur, Rajasthan 302017</a></p>
                        </div>
                    </div>
                    
                    
                </div>

                <div className='md:p-4  sm:px-[100px] px-[50px] py-4'>
                    <div className='py-4 px-2 text-white text-[22px]' >
                        Contacts
                    </div>

                    {
                        contact.map(
                            (d, i) => {
                                return (
                                    <div className='flex gap-2'>

                                        <div className='px-2 py-3 text-[#3fac9a] block  hover:text-white'>
                                            {d.icon}
                                        </div>
                                        <div 
                                            className='text-white  py-2'>
                                            <p
                                            ><a className='text-white hover:text-[#3fac9a]' href={d.url}>{d.p}</a></p>
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }


                </div>
                <div  className='sm:px-[10px] px-[50px] py-4'>
                    <div className='py-4 px-2 text-white text-[22px]' >
                        Enquire Now!
                    </div>
                    <div className="flex w-full flex-col gap-6 'md:p-4 px-2 py-4">
                        <input type="text" placeholder='Your Name' className='px-5 py-2 bg-[#205072]  border placeholder:text-white text-white text-[20px]' />
                        <input type="text" placeholder='Your Email' className='px-5 py-2 bg-[#205072]  border placeholder:text-white text-white text-[20px]' />
                        <input type="text" placeholder='Contact No.' className='px-5 py-2 bg-[#205072]  border placeholder:text-white text-white text-[20px]' />
                        <Button type="submit" className='md:w-[70%] lg:w-[50%] sm:w-[40%] w-[50%] rounded text-[22px] text-white font-bold flex justify-start bg-[#3fac9a] border-none'>Submit</Button>
                    </div>
                </div>
                <span  onClick={scrollToTop} className='fixed p-4 bg-[#3fac9a] rounded-full right-4 text-white cursor-pointer z-10 bottom-5'><IoIosArrowUp /></span>
            </div>
        </div>
    )
}
