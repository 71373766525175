import React from "react";

const Visitor = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Adds a smooth scrolling animation
    });
  };

  const handleWhatsAppClick = (props) => {
    const phoneNumber = '9414305095';
    const message = encodeURIComponent(
      `Hello hrpracharwala, I am interested in your product ${props.name} and would like to get more information. I would also like to know how I can reach out to your team. Please provide details on how to contact us.`
    );

    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappURL, '_blank');
  };

  const cart = [
    {
      image: "images/prodect/1.png",
      name: "DIE CUT BUSINESS CARDS",
    },
    {
      image: "images/prodect/2.png",
      name: "PREMIUM BUSINESS CARDS",
    },
    {
      image: "images/prodect/5_flyer-vs-leaflet.jpg",
      name: "LEAFLETS & POSTERS",
    },
    {
      image: "images/prodect/panda.jpg",
      name: "STICKERS",
    },
    {
      image: "images/prodect/atm.jpg",
      name: "ATM POUCHES",
    },
    {
      image: "images/prodect/LABLE.JPG",
      name: "GARMENT TAGS",
    },
    {
      image: "images/prodect/files.jpg",
      name: "FILES",
    },
    {
      image: "images/prodect/digital.jpg",
      name: "DIGITAL PAPER PRINTING",
    }, {
      image: "images/f1.jpg",
      name: "FLEX",
    }, {
      image: "images/h1.jpg",
      name: "Holding",
    },
  ];

  return (
    <div className="my-8 mt-[100px] p-3 mx-auto container">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
        {cart.map((data, index) => (
          <div style={{ boxShadow: ' rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px' }} key={index} className="border rounded overflow-hidden transition transform duration-300 ease-in-out hover:scale-105 p-2 ">
            <div className="relative pb-[100%]">
              <img className="absolute inset-0 h-full w-full object-cover" src={data.image} alt={data.name} />
            </div>
            <div className="text-center mt-3">
              <h1 className="text-[#5b5b5f] text-[10px] md:text-[17px] uppercase font-bold">
                {data.name}
              </h1>
              <button
                onClick={() => handleWhatsAppClick(data)}
                className="mt-3 bg-gray-800 text-white text-[10px] md:text-[16px] px-4 md:px-7 py-2 rounded transition-all duration-150 ease-in-out hover:bg-blue-600"
              >
                Buy Now
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Visitor;
