import React from 'react'

export default function CoreValues() {
    return (
        <div className='max-w-[1200px] mx-auto my-4
        '>
            <div className='text-center text-[50px] text-[#205072]'>
                <h1 className='  underline'>Core Values</h1>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 my-2'>
                <div className='p-5 md:px-16'>
                    <img src="images/corevalues/1.jpg" alt="" />
                </div>

                <div className='md:p-4 p-8 flex justify-center flex-col'>
                    <h1 className='text-[30px]  text-[#205072]'>WE ARE AN CERTIFIED AGENCY</h1>
                    <p className='pt-5 text-[#777777]'>
                        experience in the genre of Media, Marketing,
                        Advertising & PR. Augmentation in Central Govt., State Govt., Public & Private Sectors, our
                        cohesive vision, integrity and commitment have borne spectacular achievements over the
                        years weaving solutions for an enhanced client service.</p>
                </div>


            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 my-2'>
                <div className='md:px-16 p-8 flex justify-center flex-col'>
                    <h1 className='text-[30px]  text-[#205072]'>Mission</h1>
                    <p className='pt-2 text-[#777777]'>
                        Our mission is to build brands that transcends the test
                        of time, and to serve an amazing array of clients providing a single window for total brand
                        solutions. Staying in sync with the galloping technology revolution and creating new inroads for marketers to redefine their brands for sustainability by delivering effective
                        strategies, innovative ideas and earning ROI for brands.
                    </p>
                </div>
                <div className='p-5'>
                    <img src="images/corevalues/2.jpg" alt="" />

                </div>

            </div>
            <p className='md:p-4 p-8 text-justify text-md text-black'>
                HR is providing its services from the head ofce, jaipur in New Delhi & All
                Districts of Rajasthan.
                Our main services - Media Planning, Creative Solutions, Outdoor Branding,
                Digital Media, Road shows, Exhibitions, Seminars & All types of Printing
                Solutions.
                The enthuthusiastic team of young and experienced professionals who are
                dedicated with their caliber & talent.
                HR is one of the most committed, promising & skilled advertising agencies,
                having best of Manpower & Facilities.
                We have complete setup for advertising and printing solutions.
            </p>
        </div>
    )
}
