import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Main from './pages/Main';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';






const routers=createBrowserRouter(
  [
    {
      path:'/',
      element:<Main/>,
      children:[
        {
          path: '/',
          element: <Home />
        },
        {
          path: '/gallery',
          element: <Gallery />
        },
        {
          path: '/about',
          element: <About />
        },
        {
          path: '/contact',
          element: <Contact />
        },
        
      ]
    }
  ]
)


export default function App() {

  return (
    <RouterProvider router={routers}/>
  )
}
