import React from 'react';
import Prosses from '../componate/Prosses';
import Visitor from '../componate/Visitor';
import EmblaCarousel from '../componate/EmblaCarousel'
import Number from '../componate/Number';
import Logos from '../componate/Logos';



const Home = () => {
    return (
        <>
            <EmblaCarousel />
            <Visitor />
            <Prosses />
            <Logos />
            <Number />

        </>
    );
}

export default Home;
