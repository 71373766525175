import React from 'react';

export default function Prosses() {
    return (
        <div className='max-w-[1200px] mx-auto my-4 py-2'>
            <div className='flex gap-3 justify-center font-bold my-2 text-[20px] text-[#ff9f44]'>
                <span>|</span><span>Our Process</span><span>|</span>
            </div>
            <div className='flex justify-center text-[35px] mr-2 font-bold'>
                <h2>How We Do It?</h2>
            </div>
            <div className='grid md:grid-cols-3 grid-cols-1 w-[80%] m-auto'>
                <div className='w-full p-4 md:mt-20'>
                    <div className='w-[80%] relative top-2 h-[260px] flex justify-center items-center'>
                        <img src="images/half.png" className='div rounded-full absolute z-0' alt="" />
                        <div style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}} className='z-20 bg-white w-[145px] h-[145px] rounded-full flex justify-center items-center'>
                            <img src="images/logo.png" alt="" />
                        </div>
                    </div>
                    <div className='font-sans text-[22px] w-[80%] my-2 text-center font-bold'>
                        Creative Design
                    </div>
                    <div className='font-sans text-[18px] w-[80%] my-2 text-center'>
                        We start with a creative and customized design tailored to your needs.
                    </div>
                </div>
                <div className='w-full px-4'>
                    <div className='w-[80%] relative top-2 h-[260px] flex justify-center items-center'>
                        <img src="images/half.png" className='div rounded-full absolute z-0' alt="" />
                        <div style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}} className='z-20 bg-white w-[145px] h-[145px] rounded-full flex justify-center items-center'>
                            <img src="images/logo.png" alt="" />
                        </div>
                    </div>
                    <div className='font-sans text-[22px] w-[80%] my-2 text-center font-bold'>
                        High-Quality Printing
                    </div>
                    <div className='font-sans text-[18px] w-[80%] my-2 text-center'>
                        Our advanced printing technology ensures vibrant colors and sharp details.
                    </div>
                </div>
                <div className='w-full p-4 md:mt-20'>
                    <div className='w-[80%] relative top-2 h-[260px] flex justify-center items-center'>
                        <img src="images/half.png" className='div rounded-full absolute z-0' alt="" />
                        <div style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}} className='z-20 bg-white w-[145px] h-[145px] rounded-full flex justify-center items-center'>
                            <img src="images/logo.png" alt="" />
                        </div>
                    </div>
                    <div className='font-sans text-[22px] w-[80%] my-2 text-center font-bold'>
                        Secure Packaging
                    </div>
                    <div className='font-sans text-[18px] w-[80%] my-2 text-center'>
                        We package your flex cards securely to ensure they reach you in perfect condition.
                    </div>
                </div>
            </div>
        </div>
    );
}
