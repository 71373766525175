import React from 'react';
import Header from '../componate/Header'
import Footer from '../componate/Footer'
import { Outlet } from 'react-router-dom';


const Main = () => {
    return (
        <>    
        <Header/>
        <Outlet/>
        <Footer/>
        </>

    );
}

export default Main;
