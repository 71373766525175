import React from 'react';
import CoreValues from '../componate/CoreValues'
import Feature from '../componate/Feature'

const About = () => {
    return (
        <>
        <CoreValues/>
        <Feature/>
        
        </>
    );
}

export default About;
