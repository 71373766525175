import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function Logos() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
      slidesToSlide: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1
    }
  };





  return (
   <div className='max-w-[1300px] my-10 mx-auto'>
     <Carousel responsive={responsive}>
      {Array.from({ length: 32 }, (_, index) => (
        <div className="logo-container">
          <img
            key={index + 1}
            src={`images/logo/logo (${index + 1}).jpg`}
            alt={`Gallery Image ${index + 1}`}
            className="logo"
          />
        </div>
      ))}
    </Carousel>
   </div>
  );
}


