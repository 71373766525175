import React from 'react';

const MyGallery = () => {
  return (

    <div className='max-w-[1200px] mt-10 mx-auto'>
      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
        {Array.from({ length: 100 }, (_, index) => (
          <img
            key={index + 1}
            src={`images/gallerys/1 (${index + 1}).jpg`}
            alt={`Gallery Image ${index + 1}`}
            className='md:h-[280px] h-[95px] w-full'
          />
        ))}
      </div>
    </div>
  );
}

export default MyGallery;
