import React from 'react'
import CountUp from 'react-countup';


export default function Number() {
    return (
        <div className=' w-full mt-4 bg-fixed bg-[gray] '>
            <div className='max-w-[1200px]  mx-auto p-5  text-white shadow-2xl'>
                <div className='text-center'>
                    <div className='text-4xl font-extrabold'>Who are we</div>
                    <div className='py-4'>WE ARE founded in 2014, is an independent advertising agency that carries out the majority of
                        its work for: • Event Planning • Promotion • Festival and Roadshow Planning • Public Relations •
                        Social Marketing • Engagement Marketing • Project Management • POS Desig</div>
                </div>
                <div className='flex justify-center items-center text-[40px] pt-5'>

                </div>

                <div className='flex md:justify-evenly items-center gap-4 mt-5 md:flex-row flex-col py-5'>
                    <div className='flex justify-center items-center flex-col'>
                        <h1 className='text-[70px]'>
                            <CountUp start={0} duration={3.75} end={1100} />
                            <span>+</span>
                        </h1>
                        <p className='text-[25px] text-center'> Business
                            we helped
                        </p>
                    </div>
                    <div className='flex justify-center items-center flex-col'>
                        <h1 className='text-[70px]' >
                            <CountUp start={0} duration={3.75} end={350} />
                            <span>+</span>

                        </h1>
                        <p className='text-[25px] text-center'>Projects
                            we created
                        </p>
                    </div>
                    <div className='flex justify-center items-center flex-col '>
                        <h1 className='text-[70px]'>
                            <CountUp start={0} duration={6} end={28} />
                            <span>+</span>

                        </h1>
                        <p className='text-[25px] text-center pl-8 md:pl-0'> Team
                            members</p>
                    </div>

                </div>
            </div>

        </div>
    )
}
